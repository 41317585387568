// These colors are pulled from the sf-dahlia-pattern-library
// https://github.com/SFDigitalServices/sf-dahlia-pattern-library/blob/main/components/01-atoms/color.config.yml
// Whenever possible, we should use classnames to style colors but we added these in case we need them.
export const COLORS = {
  dust: '#f5f8f9',
  snow: '#f9f9f9',
  mist: '#f7f7f7',
  vapor: '#f6f6f6',
  wash: '#efefef',
  smoke: '#dedee0',
  iron: '#ccc',
  base: '#aaa',
  aluminum: '#999',
  steel: '#767676',
  charcoal: '#555',
  tuatara: '#30383a',
  oil: '#333',
  ebony: '#242c2e',
  jet: '#222',
  primary: '#0177DA',
  deep: '#0067be',
  dark: '#0d4b80',
  primaryTint: '#daeeff',
  attention: '#00bed5',
  attentionTint: '#c8f1ff',
  royal: '#b85ed5',
  royalTint: '#eed7f5',
  splash: '#ff6627',
  splashTint: '#ffd2c0',
  lush: '#99cd00',
  lushTint: '#f2ffcd',
  alert: '#e31c3d',
  alertTint: '#f9d2d8',
  success: '#2e8540',
  successTint: '#b4e5be',
  warn: '#fdb81e',
  warnTint: '#fee8b6',
  whiteSmoke: '#f5f5f5'
}
